<template>
  <div class="page page-register">
    <el-steps :active="stepActiveIndex" align-center>
      <el-step title="企业管理员信息"></el-step>
      <el-step title="企业认证"></el-step>
      <el-step title="查看审核状态"></el-step>
    </el-steps>
    <div class="page-register-step">
      <step1 v-if="stepActiveIndex === 0" @submit="changeStep(1)"></step1>
      <step2 v-else-if="stepActiveIndex === 1" @submit="changeStep(2)"></step2>
      <step3 v-else-if="stepActiveIndex === 2" @submit="changeStep(3)" ref="step3"></step3>
    </div>
  </div>
</template>

<script>
import step1 from './step1.vue'
import step2 from './step2.vue'
import step3 from './step3.vue'

export default {
  name: 'page-register',
  components: {
    step1,
    step2,
    step3
  },
  data () {
    return {
      stepActiveIndex: 0
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    changeStep (index) {
      this.stepActiveIndex = index
    },
    init () {
      let step = parseInt(this.$route.query.step || 0)
      this.changeStep(step)
      this.$nextTick(() => {
        if (step === 2) {
          this.$refs.step3 && this.$refs.step3.initData()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-register {
  width: 900px;
  margin: 0 auto;
  padding-top: 40px;
  .page-register-step {
    width: 600px;
    margin: 0 auto;
    padding: 40px 0;
  }
}
</style>
