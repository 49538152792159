<template>
  <div class="cmp cmp-step-3">
    <el-alert type="warning" :closable="false">
      注：企业实名认证后，此账号将归属于认证企业。此账号今后在平台的任何操作，都视为企业授权行为。
    </el-alert>
    <el-form :model="ruleForm" ref="ruleForm" label-width="140px">
      <el-form-item label="企业/单位名称">
        <el-input v-model="ruleForm.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码">
        <el-input v-model="ruleForm.socialCode" disabled></el-input>
      </el-form-item>
      <el-form-item label="营业执照扫描件" class="idphoto-wrapper">
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <imageUpload
              :options="{photoType:'license', photoLabel:'营业执照', photoSrc: ruleForm.businessLicenseUrl, readonly: true}"
            ></imageUpload>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>
      </el-form-item>
      <p class="form-check text-center">
        {{companyStatus}}
      </p>
    </el-form>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import imageUpload from '../../components/image-upload.vue'
import publicUrl from '../../assets/js/interface'

export default {
  name: 'cmp-step-3',
  components: {
    imageUpload
  },
  data () {
    return {
      ruleForm: {
        name: '',
        socialCode: '',
        businessLicense: '',
        businessLicenseUrl: ''
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'registerData', 'resourceInfoDict', 'companyInfo']),
    companyStatus () {
      let result = '审核中...'
      if (this.companyInfo.auditStatus === 'NOT_PASS') result = '审核未通过'
      else if (this.companyInfo.auditStatus === 'PASS') result = '审核通过'
      else if (this.companyInfo.auditStatus === 'UNVERIFIED') result = '未提交审核'
      return result
    }
  },
  watch: {
    registerData: {
      handler (n) {
        this.ruleForm = n || {
          name: '',
          socialCode: '',
          businessLicense: '',
          businessLicenseUrl: ''
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations(['setCompanyInfo']),
    ...mapActions(['companyManagerStatus', 'resourceInfoGetter']),
    init () {
      let token = localStorage.getItem('token')
      if (!token) return
      this.loadingCount++
      this.companyManagerStatus({
        vm: this.$root,
        token,
        ElMessage: this.$message
      }).finally(() => {
        this.loadingCount--
      })
    },
    initData () {
      this.loadingCount++
      this.$ajax.get(publicUrl.companyManagerInfo, {
        passportId: this.userInfo.account
      }).then(res => {
        if (res.data.code === 0) {
          let _data = res.data.data || {}
          let _company = _data.company || {}
          this.ruleForm = {
            businessLicense: _company.businessLicense,
            businessLicenseUrl: this.resourceInfoDict[_company.businessLicense],
            socialCode: _company.socialCode,
            name: _company.name
          }
          this.setCompanyInfo(_data.company)
          let fileNames = [ _company.businessLicense ].filter(v => !!v)
          this.resourceInfoGetter({vm: this.$root, fileNames}).then(() => {
            this.ruleForm.businessLicenseUrl = this.resourceInfoDict[_company.businessLicense]
          })
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-alert {
  margin-bottom: 30px;
}
.submit-btn {
  width: 100%;
}
.form-check {
  color: #606266;
}
</style>
