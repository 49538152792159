<!--
    by: yuanbiao 2021年06月23日
    name: cmp-image-upload
    notes:
-->
<template>
  <div :class="prefixCls">
    <img v-if="photoSrc"
      :class="`${prefixCls}__case_img`"
      :src="photoSrc"
    >
    <img v-else-if="Object.keys(defaultImgsDict).includes(photoType)"
      :class="`${prefixCls}__case_img`"
      :src="defaultImgsDict[photoType]"
    >
    <div v-else
      :class="`${prefixCls}__default`"
    ></div>
    <label :class="[`${prefixCls}__btn`, 'text-center']" v-if="!readonly">
      <div :class="`${prefixCls}__btn_icon`">
        <i class="fa fa-camera"></i>
      </div>
      <div :class="`${prefixCls}__btn_label`">{{photoLabel}}</div>
      <input class="hide" type="file" accept="image/*" @change="fileChange">
    </label>
  </div>
</template>

<script>
import idcard_front from '../assets/images/public/idcard_front.png'
import idcard_back from '../assets/images/public/idcard_back.png'
import license from '../assets/images/public/license.png'
const prefixCls = 'cmp-image-upload'

export default {
  name: 'cmp-image-upload',
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      prefixCls: prefixCls,
      readonly: false,
      photoType: '', // idcard_front|idcard_back|license
      photoLabel: '',
      photoFile: null,
      photoSrc: '',
      defaultImgsDict: {
        idcard_front,
        idcard_back,
        license
      }
    }
  },
  watch: {
    options: {
      handler (n, o) {
        console.log(n, o)
        this.mergeOptions(n)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    mergeOptions (data) {
      Object.keys(data).forEach(k => {
        if (this[k] === data[k]) return
        switch (k) {
          case 'photoType':
          case 'photoLabel':
          case 'photoSrc':
            this[k] = data[k] || ''
            break
          case 'readonly':
            this[k] = !!data[k]
            break
        }
      })
    },
    fileChange (e) {
      let reg = /^(?:image\/bmp|image\/cis-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x-cmu-raster|image\/x-cmx|image\/x-icon|image\/x-portable-anymap|image\/x-portable-bitmap|image\/x-portable-graymap|image\/x-portable-pixmap|image\/x-rgb|image\/x-xbitmap|image\/x-xpixmap|image\/x-xwindowdump)$/i
      if (!e.target.files) {
        this.$message.warning('未找到有效文件！')
        return
      }
      if (!reg.test(e.target.files[0].type)) {
        this.$message.warning('必须选择一个图片文件！')
        return
      }
      if (e.target.files.length === 0) {
        this.photoSrc = ''
        this.photoFile = null
        this.$emit('change', this.photoFile, this.photoSrc)
      } else if (e.target.files[0]) {
        let fr = new FileReader()
        fr.onload = (evt) => {
          this.photoSrc = evt.target.result
          this.photoFile = e.target.files[0]
          this.$emit('change', this.photoFile, this.photoSrc)
        }
        fr.readAsDataURL(e.target.files[0])
      }
    }
  }
}
</script>

<style lang="less" scoped>
// var
@imageupload-prefix-cls   : cmp-image-upload;
@imageupload-color-bg     : #def0ff;
@imageupload-color-border : #ededed;
@imageupload-color-btn    : #5bcbf4;
@imageupload-font-size    : 14px;
@imageupload-font-color   : #666;

// mixins

// styles - default
.@{imageupload-prefix-cls} {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  &__case_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__default {
    width: 100%;
    height: 100%;
    border: 2px solid @imageupload-color-border;
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 12px;
      top: 12px;
      right: 12px;
      bottom: 12px;
      border-radius: 6px;
      background-color: @imageupload-color-bg;
    }
  }
  &__btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 60%;
    cursor: pointer;
    &_icon {
      .fa {
        width: @imageupload-font-size * 3;
        height: @imageupload-font-size * 3;
        margin: auto;
        border-radius: 50%;
        background-color: @imageupload-color-btn;
        font-size: @imageupload-font-size * 1.6;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        &.xvu-plus {
          font-weight: 600;
        }
      }
    }
    &_label {
      font-size: @imageupload-font-size;
      color: @imageupload-font-color;
      line-height: 2;
      letter-spacing: .25em;
    }
  }
}
</style>
