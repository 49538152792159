<template>
  <div class="cmp cmp-step-2">
    <el-alert type="warning" :closable="false">
      注：企业实名认证后，此账号将归属于认证企业。此账号今后在平台的任何操作，都视为企业授权行为。
    </el-alert>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px">
      <el-form-item label="企业/单位名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="填写时请与营业执照上的企业注册名称相同"></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="socialCode">
        <el-input v-model="ruleForm.socialCode" placeholder="请务必与营业执照上的注册号或统一社会信用代码号一致"></el-input>
      </el-form-item>
      <el-form-item label="营业执照扫描件" prop="businessLicense" class="idphoto-wrapper">
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <imageUpload :key="3"
              :options="{photoType:'license', photoLabel:'营业执照'}"
              @change="imageUploadChange"
            ></imageUpload>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>
        <el-input v-model="ruleForm.businessLicense"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="submit-btn" type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import imageUpload from '../../components/image-upload.vue'
import publicUrl from '../../assets/js/interface'
import utils from '../../assets/js/ossUtil'

export default {
  name: 'cmp-step-2',
  components: {
    imageUpload
  },
  data () {
    return {
      loadingCount: 0,
      ruleForm: {
        name: '',
        socialCode: '',
        businessLicense: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        socialCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' }
        ],
        businessLicense: [
          { required: true, message: '请上传营业执照', trigger: 'change' }
        ]
      },
      businessLicenseUrl: ''
    }
  },
  computed: {
    ...mapState(['registerData'])
  },
  methods: {
    ...mapMutations(['setRegisterData', 'setCompanyInfo']),
    imageUploadChange (file, dataurl) {
      this.loadingCount++
      this.businessLicenseUrl = dataurl || ''
      utils.upload(file, {}, false).then((res) => {
        if (res === 'cancel') return
        this.ruleForm.businessLicense = res.resourceKey
      }).catch(err => {
        // this.$message.error('图片上传失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    submitForm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingCount++
          this.$ajaxJson.post(publicUrl.company, {
            ...this.ruleForm
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              this.setRegisterData({
                ...this.ruleForm,
                businessLicenseUrl: this.businessLicenseUrl
              })
              this.setCompanyInfo({
                auditStatus: 'NOT_AUDIT'
              })
              this.$emit('submit', 1)
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$ajaxError(err)
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.el-alert {
  margin-bottom: 30px;
}
.idphoto-wrapper {
  height: 140px;
  .el-row, .el-col {
    height: 100%;
  }
  .el-input {
    display: none;
  }
}
.submit-btn {
  width: 100%;
}
</style>
