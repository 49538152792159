import axios from 'axios'
// const baseUrl = 'http://jsxf-gateway-qa.qalb.jsxfedu.net'
const baseUrl = process.env.VUE_APP_API_URL || ''
const url = '/jsxf-open-platform/open/rs/uploadSign'
axios.defaults.headers.common['token'] = localStorage.getItem('token') || ''

const utils = {
  /**
   * 上传文件到 oss
   * @param {File | String} file File 或 文件的Base64
   * @param {Object} config axios的配置信息
   * @param {Object} share 公有 true 私有 false
   */
  // 上传文件到 oss
  upload (file, config = {}, isShare = true) {
    const defaultConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 30000
    }
    const mergeConfig = Object.assign(defaultConfig, config)

    return new Promise((resolve, reject) => {
      // 如果是 base64 字符串，转成 File 对象
      if (typeof file === 'string') {
        file = this.base64ToFile(file)
      }
      this.getUploadSign(file, isShare)
        .then(data => {
          if (data === 'cancel') resolve(data)
          const fileName = data.fileName
          const signInfo = data.signInfo// JSON.parse(data.signInfo)
          // oss 上传参数
          const formData = new FormData()
          // 存储在oss的文件路径
          formData.append('key', signInfo.dir + fileName)
          // accessKeyId
          formData.append('OSSAccessKeyId', signInfo.accessid)
          // policy
          formData.append('policy', signInfo.policy)
          // 签名
          formData.append('signature', signInfo.signature)
          // callback
          formData.append('callback', signInfo.callback)
          // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
          formData.append('file', file)
          formData.append('success_action_status', 200) // 成功后返回的操作码
          // 发起上传请求
          axios
            .post(signInfo.host, formData, mergeConfig)
            .then((res) => {
              const data = res.data
              if (data.code === 0) {
                resolve(data.data)
              } else {
                reject(data.message)
              }
            })
            .catch((error) => {
              reject(error.message)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 从服务端获取上传签名信息
  getUploadSign (file, isShare) {
    const reqUrl = baseUrl + url
    const originalName = file.name
    if (!originalName) return Promise.resolve('cancel')
    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, {
          originalName,
          share: isShare
        })
        .then(res => {
          const data = res.data
          if (data.code === 0) {
            resolve(data.data)
          } else {
            reject(data.message)
          }
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  // base64 转换为 file 类型
  base64ToFile (base64) {
    const arr = base64.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    const suffix = mime.split('/')[1]
    const filename = Date.now() + '.' + suffix
    return new File([u8arr], filename, { type: mime })
  }
}

export default utils
