<template>
  <div class="cmp cmp-step-1">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px">
      <el-form-item label="姓名" prop="realName">
        <el-input v-model="ruleForm.realName" placeholder="请输入真实姓名"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idNumber">
        <el-input v-model="ruleForm.idNumber" placeholder="请输入身份证号码"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="短信验证码" prop="code">
        <el-row type="flex" :gutter="4">
          <el-col :span="17"><el-input v-model="ruleForm.code" placeholder="请输入短信验证码"></el-input></el-col>
          <el-col :span="7">
            <el-button type="primary" style="width:100%" @click="getCode" :disabled="codeCount>0">
              {{codeCount > 0 ? `${codeCount}秒后可重试`: '获取验证码'}}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="ruleForm.password" type="password" placeholder="请用6-18位数字和字母组合"></el-input>
      </el-form-item>
      <el-form-item label="再次输入密码" prop="repeatPassword">
        <el-input v-model="ruleForm.repeatPassword" type="password" placeholder="请用6-18位数字和字母组合"></el-input>
      </el-form-item>
      <el-form-item label="身份证扫描件" prop="idPhoto" class="idphoto-wrapper">
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <imageUpload :key="0"
              :options="{photoType:'idcard_front', photoLabel:'身份证人像面'}"
              @change="imageUploadFrontChange"
            ></imageUpload>
          </el-col>
          <el-col :span="12">
            <imageUpload :key="1"
              :options="{photoType:'idcard_back', photoLabel:'身份证国徽面'}"
              @change="imageUploadBackChange"
            ></imageUpload>
          </el-col>
        </el-row>
        <el-input v-model="ruleForm.idPhoto"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="submit-btn" type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import imageUpload from '../../components/image-upload.vue'
import publicUrl from '../../assets/js/interface'
import utils from '../../assets/js/ossUtil'

export default {
  name: 'cmp-step-1',
  components: {
    imageUpload
  },
  data () {
    return {
      loadingCount: 0,
      codeCount: 0,
      codeTimer: null,
      ruleForm: {
        code: '',
        idNumber: '',
        idPhotoContrary: '',
        idPhotoFront: '',
        idPhoto: '',
        phone: '',
        realName: '',
        password: '',
        repeatPassword: ''
      },
      rules: {
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
        ],
        idNumber: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ],
        idPhoto: [
          { required: true, message: '请上传身份证扫描件', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        repeatPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    idPhoto () {
      let result = (this.ruleForm.idPhotoContrary && this.ruleForm.idPhotoFront) ? 'true' : ''
      this.$set(this.ruleForm, 'idPhoto', result)
      return result
    }
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    imageUploadFrontChange (file) {
      this.loadingCount++
      utils.upload(file, {}, false).then((res) => {
        if (res === 'cancel') return
        this.ruleForm.idPhotoContrary = res.resourceKey
      }).catch(err => {
        // this.$message.error('图片上传失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    imageUploadBackChange (file) {
      this.loadingCount++
      utils.upload(file, {}, false).then((res) => {
        if (res === 'cancel') return
        this.ruleForm.idPhotoFront = res.resourceKey
      }).catch(err => {
        // this.$message.error('图片上传失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    getCode () {
      if (!this.ruleForm.phone) {
        this.$message.warning('请输入手机号码')
        return
      }
      this.loadingCount++
      this.codeCount = 30
      this.codeTimer = setInterval(() => {
        this.codeCount--
        if (this.codeCount <= 0) {
          clearInterval(this.codeTimer)
          this.codeCount = 0
          this.codeTimer = null
        }
      }, 1000)
      this.$ajaxJson.post(publicUrl.getRegisterCode, {
        phone: this.ruleForm.phone
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg || '验证码已发出')
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.$message.error('验证码获取失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    submitForm () {
      // 为了解决computed不给力的问题
      this.ruleForm.idPhoto = (this.ruleForm.idPhotoContrary && this.ruleForm.idPhotoFront) ? 'true' : ''
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.password !== this.ruleForm.repeatPassword) {
            this.$message.error('两次密码不一致')
            return
          }
          this.loadingCount++
          this.$ajaxJson.post(publicUrl.companyRegister, {
            ...this.ruleForm
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              let _data = res.data.data || {}
              localStorage.setItem('token', _data.token)
              this.setUserInfo(_data)
              this.$emit('submit', 0)
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$ajaxError(err)
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.idphoto-wrapper {
  height: 140px;
  .el-row, .el-col {
    height: 100%;
  }
  .el-input {
    display: none;
  }
}
.submit-btn {
  width: 100%;
}
</style>
